<script setup lang="ts">
import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import type { QueryKey } from '@tanstack/vue-query';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  subject?: string;
  item: Pick<Resource, 'id'>;
  resourceApi: Pick<ResourceApi, 'name' | 'parentName' | 'parentId'> &
    Partial<Pick<ResourceApi, 'destroy'>>;
  invalidateKeys?: QueryKey[];
  resourceTranslation?: string;
  query?: Record<string, unknown>;
  onSuccess?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  subject: undefined,
  invalidateKeys: undefined,
  resourceTranslation: undefined,
  query: undefined,
  onSuccess: undefined,
});

const { t } = useI18n();
const queryClient = useQueryClient();

const isOpen = ref(false);

const { mutate: handleDeleteResource, isPending } = useMutation({
  mutationFn: async () => {
    await props.resourceApi.destroy?.(props.item.id, props.query);
  },
  onSuccess: () => {
    props.invalidateKeys?.forEach((key) => {
      queryClient.invalidateQueries({ queryKey: key });
    });

    queryClient.invalidateQueries({ queryKey: ['favorite.index'] });
    queryClient.invalidateQueries({ queryKey: [`${props.resourceApi.name}.index`] });
    queryClient.invalidateQueries({ queryKey: [`${props.resourceApi.name}.show`, props.item.id] });

    queryClient.invalidateQueries({
      queryKey: [`${props.resourceApi.name}.view.available-columns`],
    });

    queryClient.invalidateQueries({
      queryKey: [`${props.resourceApi.name}.view.show`],
      exact: false,
    });

    queryClient.invalidateQueries({
      queryKey: [`${props.resourceApi.name}.activity`, props.item.id],
    });

    if (props.resourceApi.parentName && props.resourceApi.parentId) {
      queryClient.invalidateQueries({
        queryKey: [`${props.resourceApi.parentName}.activity`, props.resourceApi.parentId],
      });
    }

    message.success(t('deleted'));

    toggleVisibility();
    props.onSuccess?.();
  },
  onError: (error) => {
    if (error.response.data && error.response.status !== 403) {
      message.error(error.response.data.message);
    }

    toggleVisibility();
  },
});

const toggleVisibility = () => {
  isOpen.value = !isOpen.value;
};

const resourceName = computed(() => t(`${props.resourceApi.name}.${props.resourceApi.name}`));
</script>

<template>
  <slot :openModal="toggleVisibility" />

  <Modal
    :title="
      t('destroy-resource', {
        resource: resourceTranslation || resourceName,
      })
    "
    :footer="null"
    width="24rem"
    :isOpen="isOpen"
    :onCancel="toggleVisibility"
  >
    <ModalContent>
      <Stack :gap="2">
        <Typography size="lg">
          <span v-html="t('destroy-resource-confirm', { subject })" />
        </Typography>

        <Cluster justifyContent="end">
          <Button appearance="outline" :onClick="toggleVisibility">
            {{ t('cancel') }}
          </Button>

          <Button type="button" :isLoading="isPending" :onClick="handleDeleteResource">
            {{ t('destroy-confirm') }}
          </Button>
        </Cluster>
      </Stack>
    </ModalContent>
  </Modal>
</template>
