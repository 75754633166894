import Echo, { Channel as EchoChannel } from 'laravel-echo';
import Pusher from 'pusher-js';

import { generateUrl } from './base-url';

export type Channel = EchoChannel;
export type UnsubscribeCallback = () => Channel;

export const echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  client: new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    channelAuthorization: {
      transport: 'ajax',
      endpoint: generateUrl('broadcasting/auth'),
      headersProvider: () => ({
        Authorization: localStorage.getItem('bearer-token'),
      }),
    },
  }),
});
