<script setup lang="ts">
import { ApiError } from '@libero/types/Error';
import Alert from '@libero/ui-framework/Alert/Alert.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import HeightMotion from '@libero/ui-framework/HeightMotion/HeightMotion.vue';
import Link from '@libero/ui-framework/Link/Link.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  error?: ApiError;
  onSubmit: () => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const handleSubmit = (event: Event) => {
  event.preventDefault();
  props.onSubmit();
};

const buttonProps = computed(() => {
  const button = props.error?.response?.data?.data?.button;

  switch (button) {
    case 'register':
      return {
        to: { name: 'auth.register' },
        text: t('auth.click-to-register'),
      };
    case 'support':
      return {
        href: `mailto:${t('auth.support-email')}`,
        text: t('auth.support-email'),
      };
    default:
      return undefined;
  }
});
</script>

<template>
  <form class="form" :onSubmit="handleSubmit">
    <HeightMotion>
      <FormLayout v-if="error">
        <Alert type="error">
          {{ error?.response?.data?.message || t('something-went-wrong') }}

          <Link v-if="buttonProps" :to="buttonProps.to" :href="buttonProps.href" isInline>
            {{ buttonProps.text }}
          </Link>
        </Alert>
      </FormLayout>
    </HeightMotion>

    <slot />
  </form>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Form/Form.scss';
</style>
