import { UpdateViewRequest } from '@libero/api-client/view/types';
import { omit } from 'lodash';

export const filterViewRequest = (data: UpdateViewRequest): UpdateViewRequest => {
  if (!data.query) return data;

  return {
    ...data,
    query: {
      ...omit(data.query, ['page']),
      filter: omit(data.query?.filter || {}, ['parent_id', 'scout']),
    },
  };
};
