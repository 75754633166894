<script setup lang="ts">
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { Textarea } from 'ant-design-vue';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { ComponentPublicInstance } from 'vue';
import { computed, onUnmounted } from 'vue';

interface Props {
  id: string;
  name: string;
  value: string | null;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  autocomplete?: string;
  rows?: number;
  minWidth?: string;
  isDirty?: boolean;
  onUpdate: (value: string | null) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  size: 'md',
  error: undefined,
  placeholder: '',
  autocomplete: undefined,
  rows: 1,
  minWidth: undefined,
  onMount: undefined,
  onUnmount: undefined,
});

const textarea = ref<ComponentPublicInstance<typeof Textarea>>();

const classes = computed(() => {
  return {
    ['size-' + props.size]: true,
    ['is-dirty']: props.isDirty,
  };
});

const handleUpdate = (value?: string) => {
  props.onUpdate(value || null);
};

onMounted(() => {
  props.onMount?.(textarea.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0">
    <Textarea
      :id="id"
      ref="textarea"
      :name="name"
      :class="classes"
      :placeholder="placeholder"
      :autoSize="{ minRows: rows }"
      :disabled="disabled"
      :required="required"
      :autocomplete="autocomplete"
      :value="value || undefined"
      :rows="rows"
      :style="{ minWidth }"
      :onUpdate:value="handleUpdate"
    />

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Textarea/Textarea.scss';
</style>
