import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceScoutApi } from '@libero/api-client/types/resource-api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { ScoutOption, ScoutQuery, SearchItem } from './types';

const scout = jsonApi('scout');

export const generateResourceScoutApi = <T = Resource>(
  resourceName: string,
  mapItem: (item: T) => ScoutOption,
  defaultQuery: ScoutQuery = {},
): ResourceScoutApi<T> => {
  const resourceScout = jsonApi(`${resourceName}`);

  const index = (query?: ScoutQuery): Promise<T[]> => {
    return resourceScout.post<T[]>('scout', { ...defaultQuery, ...query });
  };

  const options = async (query?: ScoutQuery): Promise<ScoutOption<T>[]> => {
    return index({ ...defaultQuery, ...query }).then((items) =>
      items.map((item) => ({ ...mapItem(item), item })),
    );
  };

  return {
    index,
    options,
  };
};

const index = (query: string): Promise<SearchItem[]> => {
  return scout.get(undefined, {
    searchParams: QueryString.stringify({
      query,
    }),
  });
};

export const scoutApi = {
  name: 'scout',
  index,
};
