import { ApiError, ApiErrorData } from '@libero/types/Error';
import { message } from 'ant-design-vue';
import ky, { type AfterResponseHook, Options } from 'ky';

import { trackEvent } from './analytics';
import { generateUrl } from './base-url';
import { createJsonApiClient } from './json-api-client';

export const afterResponseHooks: AfterResponseHook[] = [];

export type ApiOptions = Options;

const api = ky.create({
  credentials: 'include',
  prefixUrl: generateUrl(),
  retry: 0,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', localStorage.getItem('bearer-token') || '');
      },
    ],
    beforeError: [
      async (error) => {
        try {
          const response = (await error.response.json()) as ApiErrorData;

          if (location.href.includes('/auth/')) {
            (error as ApiError).response.data = response;

            return error;
          }

          if (error.response.status === 403) {
            message.error(response.message);
            history.back();

            trackEvent({
              category: 'API',
              action: 'response_error',
              label: response.message,
            });
          }

          (error as ApiError).response.data = response;
        } catch {
          (error as ApiError).response.data = undefined;
        }

        return error;
      },
    ],
    afterResponse: [
      (...args) => {
        afterResponseHooks.forEach((hook) => hook(...args));
      },
    ],
  },
});

const jsonApi = createJsonApiClient(api);

export { api, jsonApi };
