import { Column } from '@libero/api-client/column/types';
import { Container } from '@libero/api-client/container/types';
import { Map } from '@libero/api-client/map/types';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { IndexSensorQuery, StoreRegisterRequest, StoreSupportRequest } from './types';

const sensorRegistry = jsonApi('sensor-registry');

const index = (query: IndexSensorQuery): Promise<Container[]> => {
  return sensorRegistry.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const map = (query?: PaginationQuery): Promise<Map> => {
  return sensorRegistry.get('map', {
    searchParams: QueryString.stringify(query),
  });
};

const availableColumns = (): Promise<Column[]> => {
  return sensorRegistry.get('available-columns');
};

const show = (id: string | number): Promise<Container> => {
  return sensorRegistry.get(id.toString());
};

const support = (data: StoreSupportRequest): Promise<void> => {
  return sensorRegistry.post('support', data);
};

const register = (data: StoreRegisterRequest): Promise<void> => {
  return sensorRegistry.post('register', data);
};

export const sensorRegistryApi = {
  index,
  map,
  availableColumns,
  show,
  support,
  register,
};
