const FRONTEND_URLS = ['overzicht.cityview.nl', 'doeeenmelding.nl', 'sensorregister.nl'];

const baseUrl = ((): string => {
  const { hostname, origin } = location;

  if (hostname === 'localhost') {
    return import.meta.env.VITE_API_BASE_URL;
  }

  for (const url of FRONTEND_URLS) {
    if (hostname === url) {
      return origin.replace(url, 'production.cityview.nl');
    }

    if (hostname.endsWith(url)) {
      return origin.replace(url, 'cityview.nl');
    }
  }

  return origin;
})();

export const generateUrl = (path?: string): string => {
  const basePath = import.meta.env.VITE_API_BASE_PATH || 'api';
  const strippedPath = path?.replace(/^\/+/, '');
  return [baseUrl, basePath, strippedPath].filter(Boolean).join('/');
};

export const generateUrlGenerator =
  (basePath: string) =>
  (path?: string): string =>
    generateUrl([basePath, path].filter(Boolean).join('/'));
