/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ApiError } from '@libero/types/Error';
import { Name } from '@libero/types/Name';
import type { MutateFunction } from '@tanstack/vue-query';
import { getProperty, setProperty } from 'dot-prop';
import { isNumber, mapKeys } from 'lodash';
import { toValue } from 'vue';
import { watch } from 'vue';
import { isRef } from 'vue';
import { computed, inject, provide, reactive, type Ref, ref } from 'vue';

type WrapCallback = () => void;

interface Register<Value> {
  id: string;
  name: string;
  value: Value;
  error?: string;
  isRequired?: boolean;
  onUpdate: (newValue: Value) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

interface RegisterForm {
  error?: ApiError;
  onSubmit: WrapCallback;
}

interface UseForm<InitialValues> {
  values: InitialValues;
  isDirty: boolean;
  register: <Value = any>(name: Name<InitialValues>, isRequired?: boolean) => Register<Value>;
  setIsDirty: (isDirty: boolean) => void;
  clearValues: (names: Name<InitialValues>[]) => void;
  clearErrors: () => void;
  submit: (mutate: MutateFunction<unknown, ApiError, InitialValues, unknown>) => Promise<void>;
  registerForm: (callback: WrapCallback) => RegisterForm;
}

type RegisteredFields = Record<string, (() => void) | undefined>;

const FormSymbol = Symbol('form-symbol');

const getName = <InitialValues extends object>(name: Name<InitialValues>): string => {
  if (Array.isArray(name)) {
    return name
      .map((part, index) => {
        if (index === 0) return part;
        if (isNumber(part)) return `[${part}]`;
        return `.${part.toString()}`;
      })
      .join('');
  }

  return name as string;
};

export const useForm = <InitialValues extends object>(
  initialValues: InitialValues | Ref<InitialValues>,
  shouldFocus: boolean = true,
): UseForm<InitialValues> => {
  const registeredFields = ref<RegisteredFields>({});
  const isFocused = ref(false);
  const isDirty = ref(false);
  const error = ref<ApiError>();
  const errors = ref<Record<string, string[]>>({});

  const values = ref({ ...toValue(initialValues) }) as Ref<InitialValues>;

  const register = <Value = any>(name: Name<InitialValues>, isRequired = false) => {
    const path = getName(name);

    const value = computed(() => getProperty(values.value, path) as Value);
    const error = computed(() => errors.value[path]?.[0]);

    const onUpdate = (newValue: Value) => {
      if (newValue === value.value) return;

      if (!isDirty.value) {
        isDirty.value = true;
      }

      values.value = setProperty(values.value, path, newValue);
    };

    const onMount = (onFocus?: () => void, shouldNotFocus = false) => {
      if (!isFocused.value && shouldFocus && !shouldNotFocus) {
        isFocused.value = true;
        onFocus?.();
      }

      registeredFields.value[path] = onFocus;
    };

    const onUnmount = () => {
      delete registeredFields.value[path];
    };

    return {
      id: path,
      name: path,
      value: value.value,
      error: error.value,
      isRequired: isRequired || undefined,
      onUpdate,
      onMount,
      onUnmount,
    };
  };

  const setIsDirty = (newIsDirty: boolean) => {
    isDirty.value = newIsDirty;
  };

  const clearValues = (names: Name<InitialValues>[]) => {
    names.forEach((name) => {
      const path = getName(name);
      values.value = setProperty(values.value, path, null);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (mutate: MutateFunction<unknown, ApiError, any, unknown>) => {
    error.value = undefined;

    mutate(values.value, {})
      .then(() => {
        errors.value = {};
        error.value = undefined;
        isDirty.value = false;
      })
      .catch(async (formError) => {
        const fieldErrors = mapKeys(formError?.response?.data?.errors || {}, (_, key) => {
          if (key.startsWith('geometry')) return 'geometry';
          return key;
        });

        const hasKey = Object.keys(registeredFields.value).some((name) => name in fieldErrors);

        if (hasKey) {
          errors.value = fieldErrors;

          const [firstError] = Object.keys(fieldErrors);
          registeredFields.value[firstError]?.();
        } else {
          error.value = formError;
        }
      });
  };

  const clearErrors = () => {
    error.value = undefined;
    errors.value = {};
  };

  const registerForm = (callback: WrapCallback): RegisterForm => {
    return {
      error: error.value,
      onSubmit: callback,
    };
  };

  const form = reactive({
    values,
    isDirty,
    register,
    setIsDirty,
    clearValues,
    clearErrors,
    submit,
    registerForm,
  });

  provide(FormSymbol, form);

  if (isRef(initialValues)) {
    watch(initialValues, () => {
      values.value = { ...toValue(initialValues) };
      isDirty.value = false;
    });
  }

  return form;
};

export const useFormContext = <InitialValues extends object>(): UseForm<InitialValues> => {
  const form = inject<UseForm<InitialValues>>(FormSymbol);

  if (!form) {
    throw new Error('Form context can only be used inside of a form.');
  }

  return form;
};
