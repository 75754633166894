<script setup lang="ts">
import { trackEvent } from '@libero/utilities/analytics';
import { classNames } from '@libero/utilities/class-names';
import { isString, isUndefined } from 'lodash';
import { computed, ref } from 'vue';
import {
  RouteLocation,
  RouteLocationRaw,
  type RouterLocation,
  useRoute,
  useRouter,
} from 'vue-router';

interface Props {
  href?: string;
  to?: RouterLocation;
  type?: 'submit' | 'button' | 'reset';
  ariaLabel?: string;
  ariaExpanded?: boolean;
  isDisabled?: boolean;
  hasHover?: boolean;
  shouldOpenBlank?: boolean;
  onClick?: (event: Event) => void;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  type: 'button',
  href: undefined,
  ariaLabel: undefined,
  ariaExpanded: undefined,
  onClick: undefined,
});

const route = useRoute();
const router = useRouter();

const button = ref<HTMLElement>();

const isActive = computed(
  () => props.to && router.resolve(props.to as RouteLocationRaw).name === route.name,
);

const handleClick = (event: Event) => {
  if (props.type === 'submit') {
    return;
  }

  event.preventDefault();

  if (props.to) {
    trackEvent({
      category: 'Navigation',
      action: 'click',
      label: isString(props.to)
        ? props.to
        : (((props.to as RouteLocation).name || 'unknown') as string),
    });

    if (props.shouldOpenBlank) {
      const route = router.resolve(props.to as RouteLocationRaw);
      return window.open(route.href, '_blank');
    } else {
      return router.push(props.to);
    }
  }

  if (props.href) {
    if (props.shouldOpenBlank) {
      return window.open(props.href, '_blank');
    } else {
      return window.open(props.href, '_self');
    }
  }

  trackEvent({
    category: 'Navigation',
    action: 'click',
    label: button.value?.textContent || 'unknown',
  });

  return props.onClick?.(event);
};

const component = computed(() => {
  if (props.href) return 'a';
  return 'button';
});

const ariaExpanded = computed(() => {
  if (isUndefined(props.ariaExpanded)) return;
  return props.ariaExpanded ? 'true' : 'false';
});
</script>

<template>
  <component
    :is="component"
    ref="button"
    :href="href"
    :class="classNames({ clickable: true, isActive, isDisabled, hasHover })"
    :type="component === 'button' ? type : undefined"
    :disabled="component === 'button' ? isDisabled : undefined"
    :onClick="handleClick"
    :ariaLabel="ariaLabel"
    :ariaExpanded="ariaExpanded"
    tabindex="0"
  >
    <slot />
  </component>
</template>

<style lang="scss">
@import '@libero/ui-framework/Clickable/Clickable.scss';
</style>
